import React from "react"
import videoUrl from "../public/videos/intro.mp4"
import mobileImg from "../public/images/mobile/LogoPic.png"

function HomePage() {
  return (
    <div>
      <div id="wrap_video">
        <video id="player" loop autoPlay muted>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support this streaming content.
        </video>
      </div>

      <div className="mobile_square">
        <img alt="Logo" src={mobileImg} />
      </div>

      <div id="text_area">
        <div className="title_blue">what fills your cup?</div>

        <div className="subtitle desktop_div">
          <p>Art & Light is an amorphous concept </p>
          <p>It began with an idea of a creative collaborative</p>
          <p>combining art and mindfulness.</p>
          <p>Classes and projects for adults and kids alike.</p>
          <p>Inspired by their pursuit of self-expression.</p>
        </div>

        <div className="subtitle mobile_div">
          <p>Art & Light is an amorphous concept </p>
          <p>It began with an idea of a creative collaborative</p>
          <p>combining art and mindfulness.</p>
          <p>Classes and projects for adults and kids alike.</p>
          <p>Inspired by their pursuit of self-expression.</p>
        </div>
      </div>
    </div>
  )
}

export default HomePage
