import React from "react"
import DecorDesktopImg from "../public/images/classes/DecorHorizontal.png"
import DecorMobileImg from "../public/images/mobile/DecorSq.jpg"
import MediaDesktopImg from "../public/images/classes/mixedMediaHorizontal.png"
import MediaMobileImg from "../public/images/mobile/mixedMediaSq.jpg"
import MarzipanDesktopImg from "../public/images/classes/MarzipanHorizontal.png"
import MarzipanMobileImg from "../public/images/mobile/MarzipanSq.jpg"
import MakeYourOwnDesktopImg from "../public/images/classes/MakeYourOwnHorizontal.png"
import MakeYourOwnMobileImg from "../public/images/mobile/MakeYourOwnSq.jpg"

const ClassesPage = () => (
  <div id="text_area" style={{ margin: 0, width: "100%" }}>
    <div className="class">
      <img alt="Decor" src={DecorDesktopImg} className="class_rectangle" />
      <div className="mobile_square">
        <img alt="Decor" src={DecorMobileImg} />
      </div>
      <div className="details" style={{ padding: " 0 10%", width: "auto" }}>
        <p>
          <strong>Me &amp; My Space: décor troupe.</strong>
        </p>
        <p>
          In this, our favorite class, each participant will be transforming
          their living space. This could be a kid&#39;s room, your home office
          or your living room. We&#39;ll take notes, write down our thoughts,
          plan, sketch and make a notebook to accompany this journey (the cover
          will match your color scheme of course!).
        </p>
        <p>
          You can find many studies today concluding that the pleasure and
          reward center of our brain (the orbito-frontal cortex) lights up when
          we look at something beautiful. We are not scientists here at Art
          &amp; Light Society, but we are guided by the belief that our
          surroundings affect our mood and quality of life. We like to take this
          even a step further - that not only would adding more beauty to your
          habitat enrich your life, but that when you are a part of the creation
          of this beauty, it can bring you immense joy.
        </p>
        <p>
          We require a minimum of 3 participants to start a troupe. This can be
          a small group of kids / teens / adult or a combination thereof.
        </p>
      </div>
    </div>
    <div className="class">
      <img
        alt="Mixed Media"
        src={MediaDesktopImg}
        className="class_rectangle"
      />
      <div className="mobile_square">
        <img alt="Mixed Media" src={MediaMobileImg} />
      </div>
      <div className="details" style={{ padding: " 0 10%", width: "auto" }}>
        <p>
          <strong>
            Mixed Media: for those who like to play with their art.
          </strong>
        </p>
        <p>
          This class is designed for you to explore. Have fun with textures, and
          test out how things stack up for you.
        </p>
      </div>
    </div>
    <div className="class">
      <img
        alt="Marzipan Animals"
        src={MarzipanDesktopImg}
        className="class_rectangle"
      />
      <div className="mobile_square">
        <img alt="Marzipan Animals" src={MarzipanMobileImg} />
      </div>
      <div className="details" style={{ padding: " 0 10%", width: "auto" }}>
        <p>
          <strong>Marzipan Animals: our sweetest class.</strong>
        </p>
        <p>
          This class aims to bring you to the moment with your family and
          friends by guiding you in a fun experience together.
        </p>
        <p>
          This class is all about sharing your love, so we encourage you to make
          presents for all the special people in your life. Treat yourself by
          making these sweets, sharing them and soaking in the joy.
        </p>
      </div>
    </div>
    <div className="class">
      <img
        alt="Make Your Own Class"
        src={MakeYourOwnDesktopImg}
        className="class_rectangle"
      />
      <div className="mobile_square">
        <img alt="Make Your Own Class" src={MakeYourOwnMobileImg} />
      </div>
      <div className="details" style={{ padding: " 0 10%", width: "auto" }}>
        <p>
          <strong>
            Make your own class: a talent and passion based project.
          </strong>
        </p>
        <p>
          What is your (or your kids&#39;) artistic drive? I (Tal) would love to
          support your/ their creative growth. If you have 3 or more
          participants, I&#39;ll be happy to see how I can help foster talents
          and utilize them to form a creative project.
        </p>
        <p>
          This might be the last item on the list, but it&#39;s what I want to
          do the most -- to mobilize your talent to create something amazing!
        </p>
      </div>
    </div>
  </div>
)

export default ClassesPage
