export const talsBlue = "#0929f3"
export const selectorFieldBorderWidth = "2px"

export const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    fontFamily: "AvenirBook",
  },
}
