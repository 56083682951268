/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable react/jsx-one-expression-per-line */
import React from "react"
// import aylLogo from "../public/images/AtYourLevelLogo.svg"
import mobileImg from "../public/images/mobile/podcastingSq.jpg"
import desktopImg from "../public/images/podcastingHorizontal.png"

const PodcastingPage = () => (
  <div id="text_area" style={{ margin: "0", width: "100%" }}>
    <div className="class desktop-div">
      <img src={desktopImg} className="class_rectangle" alt="Podcasting" />

      <div className="mobile_square">
        <img src={mobileImg} alt="Podcasting" />
      </div>

      <div className="podcast left-text">
        <p>
          We have enjoyed listening to many podcasts in our family for years,
          but we never thought this would be a medium that we would ourselves
          use as a creative outlet. Forming clay, smearing bright red paint or
          playing some notes gives immediate feedback, but those sensual
          experiences in and of themselves are often a part of something greater
          - the clay might invite some sticks and a coat of paint, the red
          attracts some fuchsia and white.. oooh, and dark blue, and the notes,
          well, they harmonize with others, call out to more instruments and -
          you understand - art flourishes.
        </p>

        <p>
          As we discovered, podcasting is, too, a form of art. Its instruments
          are words, its sound quality a craft, and your voice can be expressed
          and heard! There is so much to podcasting: from having a great idea,
          improving your technique, building and understanding your audience to
          the basic needs of hosting an RSS feed, designing a logo and putting
          up a website.
        </p>

        <p>
          We are by no means experts, but we&#39;ve figured some things out and
          love learning and growing. The bottom line is that we&#39;d love to
          help, collaborate and build.
        </p>

        <div
          style={{ float: "left", width: "100%", padding: "30px 0" }}
          className="desktop_div"
        >
          {/* <div
            style={{
              float: "left",
              clear: "none",
              width: "auto",
              padding: "0 20px 0 0",
            }}
          >
            <img
              alt="At Your Level Podcast"
              src={aylLogo}
              style={{ height: 250, width: 250 }}
            />
          </div> */}
          <div>
            {/* <p style={{ marginBottom: "0.5rem" }}>Our first podcast:</p> */}
            <h2>At Your Level</h2>
            <iframe
              title="Embed Player"
              src="//play.libsyn.com/embed/episode/id/18889028/height/128/theme/modern/size/standard/thumbnail/yes/custom-color/1c04fb/time-start/00:00:00/playlist-height/200/direction/backward"
              height="128"
              width="380"
              scrolling="no"
              allowFullScreen=""
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></iframe>
            &nbsp; &nbsp; &nbsp;
            <iframe
              title="Embed Player"
              src="//play.libsyn.com/embed/episode/id/17700860/height/128/theme/modern/size/standard/thumbnail/yes/custom-color/1c04fb/time-start/00:00:00/playlist-height/200/direction/backward"
              height="128"
              width="380"
              scrolling="no"
              allowFullScreen=""
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></iframe>
            &nbsp; &nbsp; &nbsp;
            <iframe
              title="Embed Player"
              src="//play.libsyn.com/embed/episode/id/18059099/height/128/theme/modern/size/standard/thumbnail/yes/custom-color/1c04fb/time-start/00:00:00/playlist-height/200/direction/backward"
              height="128"
              width="380"
              scrolling="no"
              allowFullScreen=""
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></iframe>
          </div>
          <div>
            {/* <p style={{ marginBottom: "0.5rem" }}>Our first podcast:</p> */}
            <h2>MASHUPS by Kids Listen</h2>
            <iframe
              title="Embed Player"
              src="//play.libsyn.com/embed/episode/id/22194626/height/128/theme/modern/size/standard/thumbnail/yes/custom-color/fc048c/time-start/00:00:00/playlist-height/200/direction/backward"
              height="128"
              width="380"
              scrolling="no"
              allowFullScreen=""
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></iframe>
            &nbsp; &nbsp; &nbsp;
            <iframe
              title="Embed Player"
              src="//play.libsyn.com/embed/episode/id/22484948/height/128/theme/modern/size/standard/thumbnail/yes/custom-color/fc048c/time-start/00:00:00/playlist-height/200/direction/backward"
              height="128"
              width="380"
              scrolling="no"
              allowFullScreen=""
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></iframe>
            &nbsp; &nbsp; &nbsp;
            <iframe
              title="Embed Player"
              src="//play.libsyn.com/embed/episode/id/22485236/height/128/theme/modern/size/standard/thumbnail/yes/custom-color/fc048c/time-start/00:00:00/playlist-height/200/direction/backward"
              height="128"
              width="380"
              scrolling="no"
              allowFullScreen=""
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></iframe>
          </div>
        </div>

        <div
          style={{ float: "left", width: "100%", padding: "30px 0" }}
          className="mobile_div"
        >
          {/* <div
            style={{
              float: "left",
              clear: "none",
              width: "auto",
              padding: "0 20px 0 0",
            }}
          >
            <img
              alt="At Your Level"
              src={aylLogo}
              style={{ height: 330, width: 330 }}
            />
          </div> */}
          <div>
            {/* <p style={{ marginBottom: "0.5rem" }}>Our first podcast:</p> */}
            <h2>At Your Level</h2>
            <iframe
              title="Embed Player"
              src="//play.libsyn.com/embed/episode/id/18889028/height/128/theme/modern/size/standard/thumbnail/yes/custom-color/1c04fb/time-start/00:00:00/playlist-height/200/direction/backward"
              height="128"
              width="280"
              scrolling="no"
              allowFullScreen=""
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></iframe>
            <iframe
              title="Embed Player"
              src="//play.libsyn.com/embed/episode/id/17700860/height/128/theme/modern/size/standard/thumbnail/yes/custom-color/1c04fb/time-start/00:00:00/playlist-height/200/direction/backward"
              height="128"
              width="280"
              scrolling="no"
              allowFullScreen=""
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></iframe>
            <iframe
              title="Embed Player"
              src="//play.libsyn.com/embed/episode/id/18059099/height/128/theme/modern/size/standard/thumbnail/yes/custom-color/1c04fb/time-start/00:00:00/playlist-height/200/direction/backward"
              height="128"
              width="280"
              scrolling="no"
              allowFullScreen=""
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></iframe>
          </div>
          <div>
            {/* <p style={{ marginBottom: "0.5rem" }}>Our first podcast:</p> */}
            <h2>MASHUPS by Kids Listen</h2>
            <iframe
              title="Embed Player"
              src="//play.libsyn.com/embed/episode/id/22194626/height/128/theme/modern/size/standard/thumbnail/yes/custom-color/fc048c/time-start/00:00:00/playlist-height/200/direction/backward"
              height="128"
              width="280"
              scrolling="no"
              allowFullScreen=""
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></iframe>
            <iframe
              title="Embed Player"
              src="//play.libsyn.com/embed/episode/id/22484948/height/128/theme/modern/size/standard/thumbnail/yes/custom-color/fc048c/time-start/00:00:00/playlist-height/200/direction/backward"
              height="128"
              width="280"
              scrolling="no"
              allowFullScreen=""
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></iframe>
            <iframe
              title="Embed Player"
              src="//play.libsyn.com/embed/episode/id/22485236/height/128/theme/modern/size/standard/thumbnail/yes/custom-color/fc048c/time-start/00:00:00/playlist-height/200/direction/backward"
              height="128"
              width="280"
              scrolling="no"
              allowFullScreen=""
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default PodcastingPage
