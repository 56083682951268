import React from "react"
import mobileImg from "../public/images/mobile/AboutMeSq.jpg"
import desktopImg from "../public/images/AboutMeHorizontal.png"
import signatureImg from "../public/images/talsignature.jpg"

const detailsStyles = {
  padding: "0 10%",
  width: "auto",
}

const textareaStyles = {
  margin: 0,
  width: "100%",
}

const InformationPage = () => (
  <div id="text_area" style={textareaStyles}>
    <div className="class desktop-div">
      <img src={desktopImg} className="class_rectangle" alt="About Me" />

      <div className="mobile_square">
        <img src={mobileImg} alt="About Me" />
      </div>

      <div className="details" style={detailsStyles}>
        <p className="bold-text">
          <strong>Dear visitor,</strong>
        </p>
        <p>
          Thank you for taking the time to look, read and take this all in. I
          love collaborating and helping others express themselves artistically
          - whether the medium is a painting or a podcast, I&#39;d love to hear
          what you are passionate about!
        </p>

        <p>
          My background is a two-fold path that brought me to this endeavor.
        </p>

        <p>
          One is my love of art, which was always a powerful driver and a means
          for communication and expression. I studied Graphic Design at the Mass
          College of Art and have built a career as an Art Director over the
          past two decades. I enjoy helping others express themselves
          creatively. My latest position was at Makeblock Education, where I
          created their visual language and brand identity guidelines. Feel free
          to visit <a href="http://talkelly.com">TalKelly.com</a> to take a
          closer look at some of the work I&#39;ve done over the years.
        </p>

        <p>
          The second is a yearning for a deeper understanding of the human
          condition, happiness, and ultimately, mindfulness. It began to form in
          my late teens as I earned a BA in Philosophy and continued in my
          twenties as I became a yoga instructor. While I have veered off both
          of these specific pursuits, the practices and doctrines associated
          with them are - and always have been - a focal point of my daily life.
          This guides me to offer you my help in your creative undertakings.
        </p>

        <p>
          I strongly believe in following one&#39;s passion and in the power of
          collaboration in the pursuit of common goals. I created this website
          and Art &amp; Light Society in the hopes of finding partners young and
          old with whom to sow new seeds and flourish in new directions.
        </p>

        <img src={signatureImg} className="sig" alt="signature" />
      </div>
    </div>
  </div>
)

export default InformationPage
