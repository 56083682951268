import React, { useEffect, useState } from "react"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import ReactGA from "react-ga4"

import Header from "./components/Header"
import Selector from "./components/Selector"
import Footer from "./components/Footer"
import ScrollToTop from "./components/ScrollToTop"

import HomePage from "./pages/Home"
import ClassesPage from "./pages/Classes"
import InformationPage from "./pages/Information"
import PodcastingPage from "./pages/Podcasting"

const App = () => {
  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 500

  ReactGA.initialize("G-MS2RZ2VP35")
  ReactGA.send("pageview")

  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route
          path="/classes"
          render={() => <ClassesPage isMobile={isMobile} />}
        />
        <Route path="/information" component={InformationPage} />
        <Route path="/podcasting" component={PodcastingPage} />
      </Switch>
      <Selector />
      <Footer />
    </Router>
  )
}

export default App
