import React from "react"
import { NavLink } from "react-router-dom"

import styled from "styled-components"

const FooterContainer = styled.div`
  float: right;
  position: relative;
  left: -50%;
  text-align: left;

  ul {
    list-style: none;
    position: relative;
    left: 50%;

    a {
      text-decoration: none;
      margin: 10px 10px 10px 0;
      line-height: 0.9em;
      float: left;
      color: #fff;
      padding: 0 0 0 10px;
      text-align: center;
      white-space: nowrap;
      border: 1px solid black;
      border-width: 0 0 0 2px;

      li {
        float: left;
        position: relative; /* ie needs position:relative here*/
      }

      &:first-child {
        border-width: 0px;
      }
    }
  }
`

function Footer() {
  return (
    <>
      <div id="copyright">
        <p>
          Creativity resides in all of us. Allow yourself to pursue the form
          that speaks to you. Explore it, play with it and welcome it into your
          life. We hope you make time for your quest for happiness. We believe
          art and mindfulness is a key. Let us know where you’d like to go and
          we&#39;ll do our best to open some doors.
        </p>
      </div>
      <div id="footer">
        <FooterContainer>
          <ul>
            <NavLink activeClassName="active" to="/">
              <li className="first">home</li>
            </NavLink>
            <NavLink activeClassName="active" to="/classes">
              <li>art classes</li>
            </NavLink>
            <NavLink activeClassName="active" to="/podcasting">
              <li>podcasting</li>
            </NavLink>
            <NavLink activeClassName="active" to="/information">
              <li>about</li>
            </NavLink>
            <a href="mailto:artandlightsociety@gmail.com">
              <li>contact us</li>
            </a>
          </ul>
        </FooterContainer>
      </div>
    </>
  )
}

export default Footer
