import React from "react"
import styled from "styled-components"
import { talsBlue } from "../styles/styleVars"

const SelectorContainer = styled.div`
  background-color: ${talsBlue};
  color: #fff;
  width: 100%;
  float: left;

  .selector-area {
    margin: auto;
    text-align: center;
    font-family: "AvenirBook";
    font-size: 18px;
    padding: 0.5em 0;
    display: block;
  }
`

const Selector = () => (
  <SelectorContainer>
    <div className="selector-area">
      If you&#39;d like to create and want to collaborate, reach out!
    </div>
  </SelectorContainer>
)

export default Selector
